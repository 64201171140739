@font-face {
  font-family: "GmarketSans";
  font-style: medium;
  font-weight: 600;
  src: url("./fonts/GmarketSansTTFMedium.ttf") format("truetype");
}

@font-face {
  font-family: "GmarketSans";
  font-style: bold;
  font-weight: 800;
  src: url("./fonts/GmarketSansTTFBold.ttf") format("truetype");
}

@font-face {
  font-family: "GmarketSans";
  font-style: light;
  font-weight: 300;
  src: url("./fonts/GmarketSansTTFLight.ttf") format("truetype");
}

.App {
  color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* color: #333; */
  /* background-color: #222; */
  /* background: linear-gradient(45deg, #bb66cc 0%, #55ccff 100%);

  background-repeat: no-repeat;
  background-attachment: fixed; */
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h2,
h3 {
  font-weight: 400;
  letter-spacing: -0.03em;
}

h3 {
  line-height: 1.4em;
  /* letter-spacing: -0em; */
}

h1 {
  font-weight: 600;
  font-size: 1.8em;
  letter-spacing: -0.04em;
}

ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

::selection {
  background: blue;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: blue;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection {
  background: blue;
  color: #fff;
  text-shadow: none;
}

a {
  text-decoration: none;
  color: black;
  opacity: 0.6;
}

a:hover {
  text-decoration: none;
  color: black;
  opacity: 0.9;
}

a:visited {
  text-decoration: none;
  color: black;
}
